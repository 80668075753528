import './App.css';

import { Route, Router, Routes } from "react-router-dom";

import ConfirmOtp from './pages/ConfirmOtp';
import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          Kotak Cherry
        </p>
      </header>
      <ConfirmOtp />
    </div>
  );
}

export default App;
