import React, { useEffect, useState } from "react";

import axios from 'axios';
import qs from 'qs';
import styles from './styles.module.scss';

const ConfirmOtp = function() {
    const [pageState, setPageState] = useState('initial');
    const [mfDetails, setMfDetails] = useState();
    const [bearerToken, setBearerToken] = useState();
    const [otp, setOtp] = useState();
    const queryParams = new URLSearchParams(window.location.search);

    console.log(process.env.REACT_APP_CHERRY_BASE_URL);

    useEffect(()=>{

        const basicAuthToken = `${process.env.REACT_APP_WSO2_USERNAME}:${process.env.REACT_APP_WSO2_PASSWORD}`.toString('base64');

        const bearerTokenData = qs.stringify({
            'grant_type': 'client_credentials' 
        });
        axios.post('https://iecopreprod.kotakcherry.com/api/token', bearerTokenData,  {
            headers: { 
              'accept': 'application/json, text/plain, /', 
              'authorization': 'Basic bV9OTjlmZWZQQVNleHVZQmd4aU43ZEVjSFlJYTphNm04S0J0MlZxWTBndVIwYWNWMFpqM1lkbU1h', 
            //   'authorization': `Basic ${basicAuthToken}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) =>{
            setBearerToken(res.access_token);
        }).catch((error) => {
            console.log(error);
        });

        axios.get(
            `${process.env.REACT_APP_CHERRY_BASE_URL}/cherry-mf/v1/sips/getSipDetails`, 
            {
                headers:{
                    Authorization: `Bearer ${process.env.REACT_APP_AUTH_BEARER}`
                },
                params: {
                    sipUUID: queryParams.get('uuid')
                }
            }).then((response) => {
                setMfDetails(response.data);
            }).catch((err) =>  console.log(err));
    }, []);

    const handleButtonClick = async function( event ) {
        if(pageState === 'initial'){
            const response = await axios.post(`${process.env.REACT_APP_CHERRY_BASE_URL}/cherry-mf/v1/otp/sendSipOTP`, {
                uuid: queryParams.get('uuid')
            }, {
                headers:{
                    Authorization: `Bearer ${process.env.REACT_APP_AUTH_BEARER}`
                }
            });
            console.log({response});

            if(response.status === 200){
                console.log(response.data.message);
                setPageState('otp-confrimation');
            }
            
        }
        else{
            const otpVerifyResponse = await axios.post(`${process.env.REACT_APP_CHERRY_BASE_URL}/cherry-mf/v1/otp/verifySipOTP`, {
                uuid: queryParams.get('uuid'),
                otp
            }, {
                headers:{
                    Authorization: `Bearer ${process.env.REACT_APP_AUTH_BEARER}`
                }
            });

            if(otpVerifyResponse.status === 200){
                
                    console.log('User verified successfully!');
                    setPageState('otp-verified');
                
            }

            
        }
    }

    return (
        <div className={styles.container}>
            {pageState === 'otp-verified' ? <h1>OTP verified successfully!</h1>:
            <>
            <p>Please confirm the below details before continuing. The next step involves your authorisation for us to serve you.</p>
            {mfDetails ?
                <div className={styles.mfDetailCard}>
                   
                        <span className={styles.header}>Scheme Name</span>
                        <span>{mfDetails?.mfInfo?.data?.attributes?.scheme_name}</span>
                
                    
                        <span className={styles.header}>Scheme code</span>
                        <span>{mfDetails?.mfInfo?.data?.attributes?.scheme_code}</span>
                    

                        <span className={styles.header}>Amount</span>
                        <span>Rs. {mfDetails?.sipInfo?.data?.attributes?.sip_amount?.toLocaleString('en-IN')} /-</span>
                  
                    
                        <span className={styles.header}>Deduction Date</span>
                        <span>{mfDetails?.sipInfo?.data?.attributes?.sip_amount_debit_day} per month</span>
                   
                </div>
                : <b>Please wait while we fetch all the cherry-licious details...</b>
            }
            <div className={styles.form}>
                {pageState !== 'initial' && <input value={otp} onChange={(e) => {setOtp(e.target.value)}} />}
                <button onClick={handleButtonClick}>
                    {pageState === 'initial' ? 'Request OTP' : 'Confirm OTP'}
                </button>
            </div>
            </>}
            
        </div>
    );
};

export default ConfirmOtp;